import React, { useEffect, useState, ChangeEvent } from "react";
import {
    Box,
    Button,
    Textarea,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { CasperScenario } from "../../models/Models";
import { CasperAPI } from "../../APIs/CasperAPI";
import { useNavigate, useParams } from "react-router-dom";

const EvaluateCasperBoxPractice: React.FC = () => {
    const { scenario_id } = useParams();
    const navigate = useNavigate();

    const [scenario, setScenario] = useState<CasperScenario | null>(null);
    const [answers, setAnswers] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [finished, setFinished] = useState<boolean>(false);

    const boxBg = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("gray.200", "gray.600");

    useEffect(() => {
        if (scenario_id) {
            getAndOpenScenario(scenario_id);
        }
        setStartTime(new Date());
    }, [scenario_id]);

    const getAndOpenScenario = async (scenario_id: string) => {
        try {
            const scenario = await CasperAPI.get_casper_scenario(scenario_id);
            setScenario(scenario);
            setAnswers(Array(scenario.questions.length).fill(""));
        } catch {
            setError("Failed to load scenario");
        }
    };

    const handleEvaluateAnswer = async () => {
        setLoading(true);
        setError(null);
        try {
            if (scenario && startTime) {
                const elapsedTime = Math.floor((new Date().getTime() - startTime.getTime()) / 1000);
                const res = await CasperAPI.evaluate_casper_scenario(
                    scenario,
                    answers,
                    elapsedTime
                );
                if (res) {
                    setFinished(true);
                }
            }
        } catch (err) {
            console.error("Evaluation error:", err);
            setError("An error occurred during evaluation.");
        }
        setLoading(false);
    };

    return (
        <Box
            p={6}
            bg={boxBg}
            borderRadius="md"
            w={{ base: "90%", md: "80%", lg: "70%" }}
            maxW="4xl"
            mx="auto"
            mt={8}
            shadow="md"
            borderWidth="1px"
            borderColor={borderColor}
        >
            {finished ? (
                <Box textAlign="center" py={10} px={6}>
                    <Text fontSize="xl" mb={4}>
                        Thank you! Your answers have been submitted.
                    </Text>
                    <Button colorScheme="blue" onClick={() => navigate("/history")}>
                        Go to History
                    </Button>
                </Box>
            ) : scenario ? (
                <VStack spacing={6} align="stretch">
                    <Box>
                        <Text fontSize="2xl" fontWeight="bold" mb={2}>
                            Scenario
                        </Text>
                        <Text fontSize="md">{scenario.scenario}</Text>
                    </Box>
                    <QuestionsComponent
                        scenario={scenario}
                        answers={answers}
                        setAnswers={setAnswers}
                        onSubmit={handleEvaluateAnswer}
                        loading={loading}
                        error={error}
                    />
                </VStack>
            ) : (
                <Text>Loading scenario...</Text>
            )}
        </Box>
    );
};

type QuestionsComponentProps = {
    scenario: CasperScenario;
    answers: string[];
    setAnswers: React.Dispatch<React.SetStateAction<string[]>>;
    onSubmit: () => void;
    loading: boolean;
    error: string | null;
};

const QuestionsComponent: React.FC<QuestionsComponentProps> = ({
                                                                   scenario,
                                                                   answers,
                                                                   setAnswers,
                                                                   onSubmit,
                                                                   loading,
                                                                   error,
                                                               }) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const totalSteps = scenario.questions.length;

    const goToNext = () => {
        if (activeStep < totalSteps - 1) {
            setActiveStep(activeStep + 1);
        }
    };

    const goToPrevious = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    const handleAnswerChange = (index: number) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        const newAnswers = [...answers];
        newAnswers[index] = event.target.value;
        setAnswers(newAnswers);
    };

    return (
        <VStack spacing={6} align="stretch">
            {/* Stepper component can be included here if desired */}
            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={2}>
                    {`Question ${activeStep + 1}`}
                </Text>
                <Text fontSize="md" mb={4}>
                    {scenario.questions[activeStep]}
                </Text>
                <Textarea
                    value={answers[activeStep] || ""}
                    onChange={handleAnswerChange(activeStep)}
                    placeholder={`Type your answer to Question ${activeStep + 1} here`}
                    variant="filled"
                    size="lg"
                    resize="vertical"
                    minH="120px"
                />
                <Text
                    alignSelf="flex-end"
                    fontSize="sm"
                    color="gray.500"
                    mt={1}
                    textAlign="right"
                >
                    {answers[activeStep]?.length ?? 0}/3000 characters
                </Text>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={6}>
                {activeStep > 0 && (
                    <Button
                        onClick={goToPrevious}
                        colorScheme="gray"
                        width="48%"
                        size="lg"
                        isDisabled={loading}
                    >
                        Back
                    </Button>
                )}
                {activeStep < totalSteps - 1 ? (
                    <Button
                        onClick={goToNext}
                        colorScheme="blue"
                        width={activeStep > 0 ? "48%" : "100%"}
                        size="lg"
                        isDisabled={loading}
                    >
                        Next
                    </Button>
                ) : (
                    <Button
                        onClick={onSubmit}
                        colorScheme="green"
                        width={activeStep > 0 ? "48%" : "100%"}
                        size="lg"
                        isLoading={loading}
                        isDisabled={loading}
                    >
                        Submit
                    </Button>
                )}
            </Box>
            {error && (
                <Text color="red.500" mt={2}>
                    {error}
                </Text>
            )}
        </VStack>
    );
};

export default EvaluateCasperBoxPractice;